import React from 'react';
import type { FC, ReactNode } from 'react';

import PageContainer from 'components/PageContainer';

import s from './AuthPageContainer.module.scss';

type Props = {
  heading: string;
  intro: ReactNode | string;
  content?: ReactNode;
  extra?: ReactNode;
  footer?: string;
  seo: ReactNode;
};

const AuthPageContainer: FC<Props> = ({
  heading,
  intro,
  content,
  extra,
  footer,
  seo,
}) => (
  <PageContainer className={s.root}>
    {seo}
    <div className={s.container}>
      <h1 className={s.heading}>{heading}</h1>
      {intro && <div className={s.intro}>{intro}</div>}
      {content && <div className={s.content}>{content}</div>}
      {extra && <div className={s.extra}>{extra}</div>}
      {footer && <div className={s.footer}>{footer}</div>}
    </div>
  </PageContainer>
);

export default AuthPageContainer;
