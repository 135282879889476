import { FormError, formErrorToJSON } from 'core/utils/forms';
import type { FormErrorObject } from 'core/utils/forms';

import {
  EMAIL_VERIFICATION_CODE_LENGTH,
  EMAIL_VERIFICATION_CODE_REGEX,
} from 'config';

interface Values {
  code: number;
}

interface ValidateObject {
  cleanValues: Values;
  errors: FormErrorObject[];
}

interface ValidateCodeObject {
  code: number;
  errors: FormErrorObject[];
}

const validateCode = (code: number): ValidateCodeObject => {
  const cleanValue = code || 0;
  const errors: FormError[] = [];

  if (!cleanValue) {
    errors.push(
      new FormError(
        'Please enter your verification code.',
        'missingCode',
        'code'
      )
    );
  } else {
    if (!EMAIL_VERIFICATION_CODE_REGEX.test(cleanValue.toString())) {
      errors.push(
        new FormError(
          `Your verification code should be ${EMAIL_VERIFICATION_CODE_LENGTH} numbers.`,
          'invalidCode',
          'code'
        )
      );
    }
  }

  return {
    code: cleanValue,
    errors: errors.map((error) => formErrorToJSON(error)),
  };
};

const validate = (values: Values): ValidateObject => {
  const { code, errors: codeErrors } = validateCode(values.code);

  return {
    cleanValues: { code },
    errors: [...codeErrors],
  };
};

export default validate;
