import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import Alert from 'core/components/Alert';
import Button, { ButtonDisplay, ButtonType } from 'core/components/Button';
import Form from 'core/components/Form';
import FormRow from 'core/components/FormRow';
import TextInput, {
  TextInputAutocomplete,
  TextInputType,
} from 'core/components/TextInput';
import { ColorVariant, isNumeric } from 'core/utils';
import {
  clearFormFieldErrors,
  filterFieldErrors,
  getOnChangeValue,
} from 'core/utils/forms';
import type { FormErrorObject } from 'core/utils/forms';
import { HttpMethod } from 'core/utils/http';

import { EMAIL_VERIFICATION_CODE_LENGTH } from 'config';
import validate from 'forms/auth/verify/email';
import useApi from 'hooks/useApi';
import type { VerifyEmailApiResponse } from 'pages/api/auth/verify/email';
import { verifyEmailApiRoute } from 'routes';

type Props = {
  uuid: string;
  onSuccess: (data: VerifyEmailApiResponse) => void;
};

const DEFAULT_VALUES = {
  code: null,
};

const EmailVerifyForm: FC<Props> = ({ uuid, onSuccess }) => {
  const codeInputRef = useRef<HTMLInputElement>();
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [formErrors, setFormErrors] = useState<FormErrorObject[]>(null);
  const [forceLoading, setForceLoading] = useState(false);

  const { request, loading } = useApi('verifyEmailForm');

  const onChange = (event) => {
    const { field, value } = getOnChangeValue(event);

    // reject non numeric values
    if (field === 'code') {
      if (value !== '' && !isNumeric(value)) {
        return false;
      }
    }

    setFormErrors(clearFormFieldErrors(formErrors, field));
    setValues({
      ...values,
      [field]: value,
    });

    return true;
  };

  const submit = async (event) => {
    event.preventDefault();
    setFormErrors(null);
    setForceLoading(true);

    const { cleanValues, errors } = validate(values);

    if (errors && errors.length) {
      return setFormErrors(errors);
    }

    try {
      const response = await request({
        url: verifyEmailApiRoute(),
        method: HttpMethod.Post,
        data: {
          uuid,
          code: cleanValues.code,
        },
      });

      onSuccess(response.data);
    } catch (err) {
      console.log(err);

      if (err.response?.data?.error) {
        setFormErrors([err.response.data.error]);
        setForceLoading(false);
      } else if (err.response?.data?.errors) {
        setFormErrors(err.response.data.errors);
        setForceLoading(false);
      }
    }
  };

  // Autofocus code input
  useEffect(() => {
    if (codeInputRef.current) {
      codeInputRef.current.focus();
    }
  }, [codeInputRef]);

  return (
    <Form noValidate onSubmit={submit}>
      <FormRow>
        <TextInput
          name="code"
          type={TextInputType.Text}
          autoComplete={TextInputAutocomplete.Off}
          placeholder="Code"
          pattern="\d*"
          value={values.code}
          onChange={onChange}
          error={filterFieldErrors(formErrors, 'code').length > 0}
          disabled={loading}
          inputRef={codeInputRef}
          maxLength={EMAIL_VERIFICATION_CODE_LENGTH}
        />
      </FormRow>

      {formErrors?.length > 0 && (
        <FormRow>
          <Alert variant={ColorVariant.Danger}>
            {formErrors.map((error, i) => (
              <div key={i}>{error.message}</div>
            ))}
          </Alert>
        </FormRow>
      )}

      <FormRow>
        <Button
          display={ButtonDisplay.Block}
          type={ButtonType.Submit}
          text="Confirm"
          loading={loading || forceLoading}
        />
      </FormRow>
    </Form>
  );
};

export default EmailVerifyForm;
