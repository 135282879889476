import { FormError, FormErrorObject, formErrorToJSON } from 'core/utils/forms';

import { EMAIL_ADDRESS_REGEX, EMAIL_MAX_LENGTH } from 'config';

interface Values {
  email: string;
  password: string;
}

interface ValidateObject {
  cleanValues: Values;
  errors: FormErrorObject[];
}

interface ValidateEmailObject {
  email: string;
  errors: FormErrorObject[];
}

interface ValidatePasswordObject {
  password: string;
  errors: FormErrorObject[];
}

const validateEmail = (email: string): ValidateEmailObject => {
  const cleanValue = (email || '').trim().toLowerCase();
  const errors: FormError[] = [];

  if (!cleanValue) {
    errors.push(
      new FormError('Please enter your email address.', 'missingEmail', 'email')
    );
  } else {
    if (!EMAIL_ADDRESS_REGEX.test(cleanValue)) {
      errors.push(
        new FormError('Your email address is invalid.', 'invalidEmail', 'email')
      );
    } else if (cleanValue.length > EMAIL_MAX_LENGTH) {
      errors.push(
        new FormError(
          'Your email address is too long.',
          'emailTooLong',
          'email'
        )
      );
    }
  }

  return {
    email: cleanValue,
    errors: errors.map((error) => formErrorToJSON(error)),
  };
};

const validatePassword = (password: string): ValidatePasswordObject => {
  const cleanValue = (password || '').trim();
  const errors: FormError[] = [];

  if (!cleanValue) {
    errors.push(
      new FormError(
        'Please enter your password.',
        'missingPassword',
        'password'
      )
    );
  }

  // on the signup form we check PASSWORD_MIN_LENGTH but not here.
  // a user may have chosen a password < min length before we enforced it
  // so we don't validate the length on login

  return {
    password: cleanValue,
    errors: errors.map((error) => formErrorToJSON(error)),
  };
};

const validate = (values: Values): ValidateObject => {
  const { email, errors: emailErrors } = validateEmail(values.email);
  const { password, errors: passwordErrors } = validatePassword(
    values.password
  );

  return {
    cleanValues: { email, password },
    errors: [...emailErrors, ...passwordErrors],
  };
};

export default validate;
