import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useUser from 'hooks/data/account/useUser';
import { indexRoute, loginRoute } from 'routes';

const useRequireUser = (value = true): void => {
  const router = useRouter();
  const { data: userData } = useUser();

  useEffect(() => {
    if (userData) {
      if (value && !userData?.user) {
        // useRequireUser(true) - user must be logged in
        const { pathname, search } = window.location;

        router.replace(
          `${loginRoute()}?next=${encodeURIComponent(router.asPath)}`
        );
      } else if (!value && userData?.user) {
        // useRequireUser(false) - user must NOT be logged in
        router.replace(indexRoute()); // TODO: change this back to homeRoute
      }
    }
  }, [userData]);
};

export default useRequireUser;
