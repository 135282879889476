import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';

import Alert from 'core/components/Alert';
import Button, { ButtonDisplay, ButtonType } from 'core/components/Button';
import Form from 'core/components/Form';
import FormRow from 'core/components/FormRow';
import TextInput, {
  TextInputAutocomplete,
  TextInputType,
} from 'core/components/TextInput';
import { ColorVariant } from 'core/utils';
import {
  clearFormFieldErrors,
  filterFieldErrors,
  getOnChangeValue,
} from 'core/utils/forms';
import type { FormErrorObject } from 'core/utils/forms';
import { HttpMethod } from 'core/utils/http';

import { EMAIL_MAX_LENGTH } from 'config';
import validate from 'forms/auth/login';
import useApi from 'hooks/useApi';
import type { LoginApiResponse } from 'pages/api/auth/login';
import type { VerifyEmailApiResponse } from 'pages/api/auth/verify/email';
import { loginApiRoute } from 'routes';

type Props = {
  onSuccess: (data: LoginApiResponse & VerifyEmailApiResponse) => void;
};

const DEFAULT_VALUES = {
  email: '',
  password: '',
};

const LoginForm: FC<Props> = ({ onSuccess }) => {
  const emailInputRef = useRef<HTMLInputElement>();
  const [values, setValues] = useState(DEFAULT_VALUES);
  const [formErrors, setFormErrors] = useState<FormErrorObject[]>(null);
  const [forceLoading, setForceLoading] = useState(false);

  const { request, loading } = useApi('loginForm');

  const onChange = (event) => {
    const { field, value } = getOnChangeValue(event);

    setFormErrors(clearFormFieldErrors(formErrors, field));
    setValues({
      ...values,
      [field]: value,
    });

    return true;
  };

  const submit = async (event) => {
    event.preventDefault();
    setFormErrors(null);
    setForceLoading(true);

    const { cleanValues, errors } = validate(values);

    if (errors && errors.length) {
      setForceLoading(false);
      return setFormErrors(errors);
    }

    try {
      const response = await request({
        url: loginApiRoute(),
        method: HttpMethod.Post,
        data: {
          email: cleanValues.email,
          password: cleanValues.password,
        },
      });

      onSuccess(response.data);
    } catch (err) {
      console.log(err);

      setForceLoading(false);

      if (err.response?.data?.error) {
        setFormErrors([err.response.data.error]);
      } else if (err.response?.data?.errors) {
        setFormErrors(err.response.data.errors);
      }
    }
  };

  // Autofocus email input
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [emailInputRef]);

  return (
    <Form noValidate onSubmit={submit}>
      <FormRow>
        <TextInput
          autoComplete={TextInputAutocomplete.Email}
          disabled={loading || forceLoading}
          error={filterFieldErrors(formErrors, 'email').length > 0}
          inputRef={emailInputRef}
          maxLength={EMAIL_MAX_LENGTH}
          name="email"
          onChange={onChange}
          placeholder="Email"
          type={TextInputType.Email}
          value={values.email}
        />
      </FormRow>
      <FormRow>
        <TextInput
          autoComplete={TextInputAutocomplete.CurrentPassword}
          disabled={loading || forceLoading}
          error={filterFieldErrors(formErrors, 'password').length > 0}
          name="password"
          onChange={onChange}
          placeholder="Password"
          type={TextInputType.Password}
          value={values.password}
        />
      </FormRow>

      {formErrors?.length > 0 && (
        <FormRow>
          <Alert variant={ColorVariant.Danger}>
            {formErrors.map((error, i) => (
              <div key={i}>{error.message}</div>
            ))}
          </Alert>
        </FormRow>
      )}

      <FormRow>
        <Button
          display={ButtonDisplay.Block}
          loading={loading || forceLoading}
          text="Login"
          type={ButtonType.Submit}
        />
      </FormRow>
    </Form>
  );
};

export default LoginForm;
