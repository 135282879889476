import Link from 'next/link';
import React, { useState } from 'react';
import type { FC, MouseEvent } from 'react';

import EmailVerification from 'core/models/EmailVerification';
import AuthPageContainer from 'components/AuthPageContainer';
import SEO from 'components/SEO';
import VerifyEmailForm from 'components/pages/auth/VerifyEmailForm';
import LoginForm from 'components/pages/auth/login/LoginForm';
import { SITE_NAME } from 'config';
import useRequireUser from 'hooks/useRequireUser';
import type { LoginApiResponse } from 'pages/api/auth/login';
import type { VerifyEmailApiResponse } from 'pages/api/auth/verify/email';
import {
  accountProfileRoute,
  forgotPasswordRoute,
  loginRoute,
  signupRoute,
} from 'routes';

const LoginPage: FC = () => {
  useRequireUser(false);
  const [emailVerification, setEmailVerification] =
    useState<EmailVerification>(null);

  const backButtonClicked = (event: MouseEvent) => {
    event.preventDefault();

    setEmailVerification(null);
  };

  const onSuccess = (data: LoginApiResponse & VerifyEmailApiResponse) => {
    if (data.success) {
      const params = new URLSearchParams(window.location.search);
      const next = params.get('next') || accountProfileRoute(); // NOTE: after real users are signing up, this should go to homeRoute()

      window.location.href = next;
    } else if (data.emailVerification) {
      setEmailVerification(data.emailVerification);
    }
  };

  const seo = <SEO title="Login" />;

  return (
    <>
      {emailVerification ? (
        <AuthPageContainer
          seo={seo}
          heading="Check your inbox"
          intro={
            <>
              We&apos;ve emailed a code to{' '}
              <strong>{emailVerification.email}</strong>
            </>
          }
          content={
            <VerifyEmailForm
              uuid={emailVerification.uuid}
              onSuccess={onSuccess}
            />
          }
          extra={
            <>
              <a href={loginRoute()} onClick={backButtonClicked}>
                &larr; Back
              </a>
            </>
          }
        />
      ) : (
        <AuthPageContainer
          seo={seo}
          heading="Login"
          intro={`Login to your ${SITE_NAME} account`}
          content={<LoginForm onSuccess={onSuccess} />}
          extra={
            <>
              <div>
                Don&apos;t have an account?{' '}
                <Link href={signupRoute()}>
                  <a>Sign up</a>
                </Link>
              </div>
              <div>
                <Link href={forgotPasswordRoute()}>
                  <a>I forgot my password</a>
                </Link>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default LoginPage;
